import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import './Contact.scss';
/* import Discord from '../../assets/discord.svg'; */
import Telegram from '../../assets/telegram.svg';

function Contact() {

    const telegramNavigate = () => {
        window.open('https://t.me/addlist/JqOET27MMLQ5Yzgx', '_blank');
    }

    const telegramNavigateBackup = () => {
        window.open('https://t.me/DEAL_ARCHITECTS_GATEWAY', '_blank');
    }

    /* const discordNavigate = () => {
        window.open('https://discord.gg/CwrUPXKP', '_blank');
    } */

    return <div className="h-full w-screen contact-bg">
        <div className='flex md:flex-row md:justify-evenly md:items-center md:h-full md:w-screen flex-col gap-8 pb-[150px]'>
            <div className='flex flex-col px-12 pt-8 gap-8 justify-center items-center md:items-start md:w-5/12'>
                        <h1 className='text-center uppercase text-white font-oxygen font-semibold text-sm md:text-lg md:w-3/4 md:text-left'>Leave Your Email In case Sites
                    & Groups Are Down</h1>
                <div className='glass flex flex-col gap-4 z-10 items-center justify-center p-8 w-full shadow-lg'>
                    <Input placeholder={'Your Name'} />
                    <Input placeholder={'Email'} />
                </div>
               
                    <Button small={true} width={'230px'}>Stay with us</Button>
                
            </div>
            <div>
                <h1 className='text-center uppercase text-white font-oxygen font-semibold text-xl pb-4'>Contact Us</h1>
                <div className='flex flex-col gap-4 md:gap-8 items-center justify-center'>
{/*                     <div onClick={discordNavigate} className='hover:border-2 box-border h-[60px] w-[60px] md:h-24 md:w-24 icon-glass rounded-xl shadow-lg shadow-violet-700/50 flex justify-center items-center'>
                    <img src={Discord} alt='Discord' width={'70%'}/>
                    </div> */}
                    <div onClick={telegramNavigate} className='hover:border-2 px-2 icon-glass rounded-xl shadow-lg shadow-sky-700/50 flex gap-4 justify-center items-center w-[200px]'>
                        <img src={Telegram} alt='Telegram' width={'32px'} />
                        <span className='text-white text-lg'>
                            Telegram Group
                        </span>
                        
                    </div>
                    <div onClick={telegramNavigateBackup} className='hover:border-2 px-2 icon-glass rounded-xl shadow-lg shadow-sky-700/50 flex gap-4 justify-center items-center '>
                    <img src={Telegram} alt='Telegram' width={'32px'} />
                        <div className='text-white text-lg'>
                            Backup Group
                        </div>
                        
                    </div>
                </div>
                
            </div>
        </div>
    </div>
}

export default Contact;