import './Stores.scss';

function Stores() {
    return <div className="h-full w-screen flex flex-col gap-4 px-12 py-4 text-left">
        <div>
            <h1 className="text-white uppercase text-2xl">Available stores</h1>
            <h3 className="text-white uppercase">CHOOSE FROM OUR LIST:</h3>
        </div>
        <div className='hidden md:flex stores-box p-4 rounded-lg shadow-xl'>
            <table className='table-fixed w-full border-spacing-y-4 border-collapse'>
                <thead className='uppercase text-zinc-600 h-12 '>
                    <th className='rounded-l-lg bg-zinc-800'>

                    </th>
                    <th className='bg-zinc-800'>
                        Store Name
                    </th>
                    <th className='bg-zinc-800'>
                        Max Amount
                    </th>
                    <th className='bg-zinc-800'>
                        Max Items
                    </th>
                    <th className='bg-zinc-800'>
                        Time Frame
                    </th>
                    <th className='rounded-r-lg bg-zinc-800'>
                        Fee
                    </th>
                </thead>
                <tbody className='text-white'>
                    <tr className='h-28'>
                        <td>
                            <div className='h-16 w-16 rounded-lg bg-slate-500 store-icon ml-10'>
                                <img src='https://brandemia.org/contenido/subidas/2022/11/marca-ralph-lauren-original-1024x572.png' alt='Store Name' />
                            </div>
                        </td>
                        <td>
                            Ralph Lauren
                        </td>
                        <td>
                            $3.500
                        </td>
                        <td>
                            8
                        </td>
                        <td>
                            2-7 days
                        </td>
                        <td>
                            40% - $200 Minimum Fee
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
        <div className='stores-box p-4 rounded-md shadow-lg md:hidden'>
            <div className='flex gap-4 items-center'>
                <div className='h-16 w-16 rounded-lg bg-slate-500 store-icon'>
                    <img src='https://brandemia.org/contenido/subidas/2022/11/marca-ralph-lauren-original-1024x572.png' alt='Store Name' />
                </div>
                <h1 className='text-white font-oxygen'>Ralph Lauren</h1>
            </div>
            <div className='w-full mt-4'>
                <table className='table-fixed w-full'>
                    <tbody className='text-sm'>
                        <tr>
                            <td className='uppercase text-zinc-600'>Max AMount</td>
                            <td className='text-white'>$3.500</td>
                        </tr>
                        <tr>
                            <td className='uppercase text-zinc-600'>Max Items</td>
                            <td className='text-white'>8</td>
                        </tr>
                        <tr>
                            <td className='uppercase text-zinc-600'>Time Frame</td>
                            <td className='text-white'>2-7 days</td>
                        </tr>
                        <tr>
                            <td className='uppercase text-zinc-600'>Fee</td>
                            <td className='text-white'>40% - $200 Minimum Fee</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div className='stores-box px-8 py-4 text-white text-center rounded-md shadow-lg'>
            More stores coming soon.
        </div>

    </div>
}

export default Stores;