import './About.scss';
import { Link } from 'react-router-dom';
import Button from '../../components/Button/Button';
import CounterCard from '../../components/CounterCard/CounterCard';

function About() {
    return <div className="h-full w-screen flex flex-col pt-6 about-bg md:justify-center md:items-start box-content font-oxygen gap-8 pb-12">

        <div className='z-10 w-screen flex flex-col justify-center px-16 text-white gap-6'>
            <div className="title uppercase font-bold text-white text-2xl md:text-6xl md:pl-12">About Us</div>
            <div className='flex justify-between md:pl-12 md:gap-20'>
            <div className='flex flex-col gap-6 md:w-1/2 '>
                        <p className='text-xs md:text-lg leading-1 font-extralight md:leading-[24px]'>
                    At Deal Architect, our mission extends beyond mere discounts – we're dedicated to cultivating a culture of savings. Come join us in transforming the way you shop, and let's embark on a journey where accessing the best deals is not just a possibility but a meticulously crafted experience.
                    </p>
                    <p className='leading-0 font-oxygen md:text-2xl md:font-bold'>
                    Start saving and make every purchase a celebration of smart choices.
                    </p>
                    <p className='text-xs font-extralight leading-1 md:text-lg md:leading-[24px]'>
                    Join our community of savvy shoppers who love scoring great deals. Share your experiences, discover hidden gems, and be part of a network that values smart spending.
                    </p> 
                </div>
                <div className='relative mr-80 md:block hidden'>
                    <div style={{marginLeft: '-85px'}}>
                    <CounterCard icon={'Card'} width={'210px'} height={'80px'} fromN={1299} toN={764} />
                    </div>
                    <div style={{ marginTop: '65px' }} className='z-40'>
                    <CounterCard icon={'Ticket'} width={'210px'} height={'80px'} fromN={899} toN={530} />
                    </div>
                    <div style={{marginLeft: '-35px', marginTop: '130px'}}>
                    <CounterCard icon={'Phone'} width={'210px'} height={'80px'} fromN={2570} toN={1840} />
                    </div>
                </div>
            </div>
             
            </div>

        
        <div className='visible md:hidden w-full flex justify-center'>
        <Link to={'/contact'}><Button width={'100%'} small={true}>
                Start Saving Now</Button></Link>
        </div>
        <div className='hidden md:flex ml-28'>
        <Link to={'/contact'}><Button width={'429px'}>
                Start Saving Now</Button></Link>
        </div>
    </div>
}

export default About;