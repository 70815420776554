import { useEffect, useState, useCallback } from 'react';
import Card from '../../assets/card.svg';
import Ticket from '../../assets/ticket.svg';
import Phone from '../../assets/phone.svg';
import './CounterCard.scss';

const CounterCard = (props) => {
    const { width, height, icon, fromN, toN} = props;
    const [style, setStyle] = useState(null);
    const [ iconImg, setIconImg ] = useState(null);
    
    useEffect(() => {
        const cardStyle = { top: '-75%', left: '-25%' }
        const ticketStyle = {top: '-85%', width: '90%', left: '-15%'}
        const phoneStyle = { top: '-80%', width: '85%', left: '-15%' }

        switch (icon) {
            case 'Card':
                setStyle(cardStyle)
                setIconImg(Card)
                break;
            case 'Ticket':
                setStyle(ticketStyle)
                setIconImg(Ticket)
                break;
            case 'Phone':
                setStyle(phoneStyle)
                setIconImg(Phone)
                break;
            default: setStyle(cardStyle)
        }
    }, [icon])

    return <div className="glass absolute flex justify-center align-center overflow-hidden" style={{ width: width, height: height }}>
        <img src={iconImg} alt='Icon' className='absolute' style={style} />
        <div style={{marginRight: '-90px', position: "absolute", top: '10px'}}>
            <Counter start={fromN} finish={toN} />
        </div>
        
    </div>
}

export function Counter(props) {
    const [countDown, setCountDown] = useState(props.start);
    const [isFinal, setIsFinal] = useState(false);

    useEffect(() => {
        setIsFinal(false);
    }, [])

    const counterAnimation = useCallback(() => {
        let currentCount = props.start;
    
        const updateCount = () => {
          const roundedCount = Math.round(currentCount);
          setCountDown(roundedCount);
    
          currentCount -= currentCount * 0.02;
    
          if (currentCount > props.finish) {
            setTimeout(updateCount, 65);
          } else {
            setIsFinal(true);
          }
        };
    
        updateCount();
      }, [props.start, props.finish]); // Include props in the dependency array
    
      useEffect(() => {
        counterAnimation();
        const intervalId = setInterval(() => {
          setCountDown(props.start);
          setIsFinal(false);
          counterAnimation();
        }, 5000);
    
        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
      }, [counterAnimation, props.start]); // Include counterAnimation in the dependency array

    return <div className={isFinal ? 'counter-final' : 'counter'}><span className='text-2xl'>$</span>{countDown}</div>;
}

export default CounterCard;