import { Outlet, NavLink } from "react-router-dom";
import NavbarLogo from '../assets/NavbarLogo.svg';
import MobileNavBar from "../components/MobileNavBar/MobileNavBar";
import './Layout.scss'

const Layout = () => {
  return (
    <>
          
          <div className="flex flex-col bg-gradient-to-b h-screen from-slate-900 to-midnight overflow-hidden relative">
          <div className="w-screen flex justify-center md:justify-between bg-transparent pl-8 pb-4 pr-16 pt-6 items-center z-10">
              <div className="flex h-100 itmes-center justify-center gap-8">
                      <img src={NavbarLogo} alt="Logo" width={'64px'} />
                      <div className="pb-3 text-center text-nowrap uppercase tracking-widest flex items-end gap text-white text-xl md:text-2xl font-bold font-oxygen">Deal Architect</div>
              </div>
            <ul className="hidden md:flex gap-24 justify-around text-white font-oxygen uppercase items-center text-xl">
            <li>
                <NavLink to="/">Home</NavLink>
                      </li>
                      <li>
                <NavLink to="/about">About Us</NavLink>
                  </li>
            <li>
                <NavLink to="https://rentry.co/DealArchitectsStoreList">Stores</NavLink>
            </li>
            <li>
                <NavLink to="/contact">Contact Us</NavLink>
            </li>
            </ul>
        </div>
        <div className="flex-1 w-screen overflow-y-scroll overflow-x-hidden scrollabale">
          <Outlet />
        </div>
        
        <div className="absolute md:hidden z-20 bottom-0 flex justify-center items-center w-screen">
          <MobileNavBar/>
        </div>
          </div>
        
    </>
  )
};

export default Layout;