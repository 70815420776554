import React from "react";

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.667"
      >
        <path d="M16 21.333a5.333 5.333 0 100-10.666 5.333 5.333 0 000 10.666z"></path>
        <path d="M21.333 10.667v6.666a4 4 0 008 0V16a13.334 13.334 0 10-5.226 10.587"></path>
      </g>
    </svg>
  );
}

export default Icon;