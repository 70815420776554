import { AboutIcon, ContactIcon, HomeIcon, StoresIcon } from '../icons';
import { NavLink } from 'react-router-dom';
import './MobileNavBar.scss';

function MobileNavBar() {
    const links = [
        {
            name: 'Home',
            link: '/',
            icon: <HomeIcon />
        },
        {
            name: 'About Us',
            link: '/About',
            icon: <AboutIcon />
        },
        {
            name: 'Stores',
            link: 'https://rentry.co/DealArchitectsStoreList',
            icon: <StoresIcon />
        }, {
            name: 'Contact Us',
            link: '/Contact',
            icon: <ContactIcon />
        },
    ]

    return <div className="w-96 MobileNavBar flex justify-around py-2">
        {links && links.map((link, i) => (
            <div className='flex flex-col justify-center items-center gap-2'>
                <NavLink exact activeClassName='text-white' className={`uppercase link text-xs font-extralight flex flex-col items-center justify-center gap-2`} to={link.link}>{link.icon}{link.name}</NavLink>
            </div>
        )
        )}
    </div>
}

export default MobileNavBar;