import './Home.scss';
import DealArchitectMainLogo from '../../assets/DealArchitectMainLogo.svg';
import Button from '../../components/Button/Button';
import CounterCard from '../../components/CounterCard/CounterCard';
import React from 'react';
import { Link } from 'react-router-dom';

function Home() {


    return <div style={{ paddingBottom: '170px' }} className='h-full w-screen flex flex-col justify-center items-center home-bg box-content pb-40'>
        {/* Mobile */}
        <div className='relative h-48 w-screen visible md:hidden flex justify-center items-center'>
            <div style={{ marginTop: '-35%', marginLeft: '-60%', zIndex: 10 }}>
                <CounterCard icon={'Card'} width={'210px'} height={'80px'} fromN={1299} toN={764} />
            </div>

            <div style={{ marginLeft: '10%' }}>
                <CounterCard icon={'Ticket'} width={'210px'} height={'80px'} fromN={899} toN={530} />
            </div>

        </div>
        {/* Desktop */}
        <img src={DealArchitectMainLogo} alt='Logo' width={'220px'} className='md:flex hidden' style={{ marginTop: '80px' }} />
        <h1 className="md:flex hidden title uppercase font-bold text-white z-10">
            Deal Architect
        </h1>

        {/* Mobile */}
        <div className='hidden md:flex flex-col w-screen justify-center items-center z-10'>
            <p className="text-center w-5/12 text-white text-lg font-oxygen">
                Welcome to the <strong className="uppercase">Deal Architect</strong>, where savings meet simplicity! We are your dedicated partners in bringing you exclusive discounts and unbeatable deals from the biggest companies around.
            </p>
            <p className="w-5/12 text-center text-white text-lg font-oxygen mt-8 z-10">In case our groups are taken down visit our site for new contact or leave your contact and we'll reach out to you.</p>
        </div>
        {/* Desktop */}
        <div className='flex md:hidden flex-col w-screen justify-center items-center gap-8'>
            <p className='text-white text-center w-9/12 text-2xl'>
                Welcome to the <strong className='uppercase font-bold'>Deal Architect</strong>, where savings meet simplicity!
            </p>
            <p className='text-white text-center w-3/4 text-sm font-extralight'>
                We are your dedicated partners in bringing you exclusive discounts and unbeatable deals from the biggest companies around.
            </p>
            <p className='text-white text-center w-3/4 text-sm font-extralight'>
            In case our groups are taken down visit our site for new contact or leave your contact and we'll reach out to you.
            </p>
        </div>
        <div className='flex flex-row pt-8 gap-8'>
            <a href='https://t.me/addlist/Iz3NBWlf7tkyY2Ix' target='blank'>
                <Button small>Telegram Group</Button>
            </a>
            <a href='https://t.me/DEAL_ARCHITECTS_GATEWAY' target='blank'>
                <Button small>Backup Group</Button>
            </a>
            
        </div>
        <div className='pt-6 pb-4'>
            <Link to={'contact'}><Button small>Groups Down?</Button></Link>
        </div>

    </div>;
}

export default Home;