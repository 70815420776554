import React from "react";

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
          viewBox="0 0 32 32"
          {...props}
    >
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.667"
      >
        <path d="M8 2.667L4 8v18.667a2.667 2.667 0 002.667 2.666h18.666A2.667 2.667 0 0028 26.667V8l-4-5.333H8z"></path>
        <path d="M4 8h24"></path>
        <path d="M21.333 13.333a5.333 5.333 0 11-10.666 0"></path>
      </g>
    </svg>
  );
}

export default Icon;